<div class="dl-redirect-container">
  @if (info(); as info) {
    <div
      [ngClass]="{ info_success: info.success, info_error: !info.success }"
      class="info"
    >
      {{ info.message | translate }}
    </div>
  } @else {
    <div class="loading-area">
      <app-loading-spinner></app-loading-spinner>
    </div>
  }
</div>
